import React from 'react';
import PropTypes from 'prop-types';

import PageLink from './PageLink';
import PageTextCopy from './PageTextCopy';
import PageTextSubTitle from './PageTextSubTitle';

// Used for bullet points with number throughout website
const PageTextBulletNumber = (props) => (
  <div
    className={`${props.parentClass} bullet${
      props.class ? ` ${props.class}` : ''
    }`}
  >
    <span>
      <span className="bullet-icon number">
        <span>{props.number}</span>
      </span>
    </span>
    <span className="bullet-text">
      <PageTextSubTitle parentClass="bullet" text={props.title} />
      {props.url ? (
        <PageLink parentClass="bullet" text={props.copy} url={props.url} />
      ) : (
        <PageTextCopy parentClass="bullet" text={props.copy} />
      )}
    </span>
  </div>
);

PageTextBulletNumber.propTypes = {
  class: PropTypes.string,
  number: PropTypes.number.isRequired,
  parentClass: PropTypes.string.isRequired,
  url: PropTypes.string,
};

PageTextBulletNumber.defaultProps = {
  class: '',
  url: null,
};

export default PageTextBulletNumber;
