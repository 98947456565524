import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'es6-promise';
import 'isomorphic-fetch';

import React, { Component } from 'react';
import RevealConfig from 'react-reveal/globals';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Smoothscroll from 'smoothscroll-polyfill';

import CookieConsent from './components/CookieConsent';
import LoadingSpinner from './components/LoadingSpinner';
import Navigation from './components/Navigation';
import Page from './components/Page';
import ScrollToTop from './components/ScrollToTop';
import CaseStudies from './pages/CaseStudies';
// import News from './pages/News';
// import NewsArticle from './pages/NewsArticle';

RevealConfig({ ssrReveal: true });

Smoothscroll.polyfill();
class App extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);

    this.state = {
      data: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    // Fetch data from server before App.js component will be mounted
    this.fetchData();
  }

  // Fetch data from jsons in /public/data and CMS
  fetchData() {
    // const apiToken = '5f6e018c8550e45f4352d130d8be50'; // API token for CMS
    // const apiURL = 'https://www.nice-system.com';

    const fetchOptions = {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    // Get four latest new articles from CMS to display them under "Discover the latest"
    // 4th article is used if one of the latest articles is displayed full size
    // Content, featured image and file attachment will not be fetched at this point
    // const latestArticlesOptions = {
    //   method: 'post',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({
    //     fields: {
    //       content: 0,
    //       featuredImage: 0,
    //       fileAttachment: 0,
    //     },
    //     filter: {
    //       published: true,
    //     },
    //     limit: 4,
    //     sort: {
    //       customDate: -1,
    //     },
    //   }),
    //   ...fetchOptions,
    // };

    // Fetch data from JSON
    function fetchJSON(path, options = fetchOptions) {
      const fetchResponse = fetch(path, options).then((response) =>
        response.json(),
      );

      return fetchResponse;
    }

    const data = {};

    // Fetching of data from JSONs happens here
    const componentContact = fetchJSON('/data/ComponentContact.json');
    const footerItems = fetchJSON('/data/FooterItems.json');
    // const latestArticles = fetchJSON(
    //   `${apiURL}/cockpit/api/collections/get/news?token=${apiToken}`,
    //   latestArticlesOptions,
    // );
    const navItems = fetchJSON('/data/NavItems.json');
    // const newsScheme = fetchJSON(
    //   `${apiURL}/cockpit/api/collections/collection/news?token=${apiToken}`,
    // );
    const pageCaseStudies = fetchJSON('/data/PageCaseStudies.json');
    const pageCaseStudyAudio = fetchJSON('/data/PageCaseStudyAudio.json');
    const pageCaseStudyDial = fetchJSON('/data/PageCaseStudyDial.json');
    const pageContact = fetchJSON('/data/PageContact.json');
    const pageCustomerSupport = fetchJSON('/data/PageCustomerSupport.json');
    const pageFutureFaceOfNice = fetchJSON('/data/PageFutureFaceOfNice.json');
    const pageHome = fetchJSON('/data/PageHome.json');
    const pageInsights = fetchJSON('/data/PageInsights.json');
    const pageLifecycle = fetchJSON('/data/PageLifecycle.json');
    const pageLifecycleC300 = fetchJSON('/data/PageLifecycleC300.json');
    const pageLifecycleC350 = fetchJSON('/data/PageLifecycleC350.json');
    const pageLifecycleTraining = fetchJSON('/data/PageLifecycleTraining.json');
    const pageLifecycleUpgrades = fetchJSON('/data/PageLifecycleUpgrades.json');
    const pageLifecycleUpgradesC300 = fetchJSON(
      '/data/PageLifecycleUpgradesC300.json',
    );
    const pagePhilosophy = fetchJSON('/data/PagePhilosophy.json');
    const pageSystem = fetchJSON('/data/PageSystem.json');
    const pageSystemAudio = fetchJSON('/data/PageSystemAudio.json');
    const pageSystemAudioOmniFi = fetchJSON('/data/PageSystemAudioOmniFi.json');
    const pageSystemConnectivity = fetchJSON(
      '/data/PageSystemConnectivity.json',
    );
    const pageSystemMedia = fetchJSON('/data/PageSystemMedia.json');
    const pageSystemPEDs = fetchJSON('/data/PageSystemPEDs.json');
    const pageSystemUserInterface = fetchJSON(
      '/data/PageSystemUserInterface.json',
    );
    const pageSystemVideo = fetchJSON('/data/PageSystemVideo.json');
    const pagePrivacyPolicy = fetchJSON('/data/PagePrivacyPolicy.json');

    // Fetched data will be assigned to data object
    Promise.all([
      componentContact,
      footerItems,
      // latestArticles,
      navItems,
      // newsScheme,
      pageCaseStudies,
      pageCaseStudyAudio,
      pageCaseStudyDial,
      pageContact,
      pageCustomerSupport,
      pageFutureFaceOfNice,
      pageHome,
      pageInsights,
      pageLifecycle,
      pageLifecycleC300,
      pageLifecycleC350,
      pageLifecycleTraining,
      pageLifecycleUpgrades,
      pageLifecycleUpgradesC300,
      pagePhilosophy,
      pageSystem,
      pageSystemAudio,
      pageSystemAudioOmniFi,
      pageSystemConnectivity,
      pageSystemMedia,
      pageSystemPEDs,
      pageSystemUserInterface,
      pageSystemVideo,
      pagePrivacyPolicy,
    ]).then((values) => {
      data.layout = {
        contact: values[0],
        footer: values[1],
        // latestArticles: values[2],
      };
      data.navItems = values[2];
      // data.newsScheme = values[4];
      data.pageCaseStudies = values[3];
      data.pageCaseStudyAudio = values[4];
      data.pageCaseStudyDial = values[5];
      data.pageContact = values[6];
      data.pageCustomerSupport = values[7];
      data.pageFutureFaceOfNice = values[8];
      data.pageHome = values[9];
      data.pageInsights = values[10];
      data.pageLifecycle = values[11];
      data.pageLifecycleC300 = values[12];
      data.pageLifecycleC350 = values[13];
      data.pageLifecycleTraining = values[14];
      data.pageLifecycleUpgrades = values[15];
      data.pageLifecycleUpgradesC300 = values[16];
      data.pagePhilosophy = values[17];
      data.pageSystem = values[18];
      data.pageSystemAudio = values[19];
      data.pageSystemAudioOmniFi = values[20];
      data.pageSystemConnectivity = values[21];
      data.pageSystemMedia = values[22];
      data.pageSystemPEDs = values[23];
      data.pageSystemUserInterface = values[24];
      data.pageSystemVideo = values[25];
      data.pagePrivacyPolicy = values[26];

      // Fetched data will be added to current state
      this.setState({
        data,
        isLoading: false,
      });
    });
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner fullHeight />;
    }

    return (
      <Router>
        <ScrollToTop delay={500}>
          <CookieConsent />
          <div className="App">
            <Navigation data={this.state.data.navItems} />
            <Route
              render={({ location }) => (
                <TransitionGroup>
                  <CSSTransition
                    key={location.key}
                    classNames="fade"
                    timeout={1500}
                  >
                    <Switch location={location}>
                      <Route
                        path="/"
                        render={({ match: { url } }) => (
                          <Switch>
                            <Route
                              exact
                              path={`${url}`}
                              render={() => (
                                <Page
                                  data={this.state.data.pageHome}
                                  layout={this.state.data.layout}
                                />
                              )}
                            />
                            <Route
                              path={`${url}contact`}
                              render={() => (
                                <Page
                                  data={this.state.data.pageContact}
                                  layout={this.state.data.layout}
                                />
                              )}
                            />
                            <Route
                              path={`${url}services-and-upgrades`}
                              render={({
                                match: { url: servicesAndUpgradesUrl },
                              }) => (
                                <Switch>
                                  <Route
                                    exact
                                    path={`${servicesAndUpgradesUrl}`}
                                    render={() => (
                                      <Page
                                        data={
                                          this.state.data.pageCustomerSupport
                                        }
                                        layout={this.state.data.layout}
                                      />
                                    )}
                                  />
                                  <Route
                                    path={`${servicesAndUpgradesUrl}/c300-upgrade`}
                                    render={() => (
                                      <Page
                                        data={this.state.data.pageLifecycleC300}
                                        layout={this.state.data.layout}
                                      />
                                    )}
                                  />
                                  <Route
                                    path={`${servicesAndUpgradesUrl}/c350-upgrade`}
                                    render={() => (
                                      <Page
                                        data={this.state.data.pageLifecycleC350}
                                        layout={this.state.data.layout}
                                      />
                                    )}
                                  />
                                  <Route
                                    path={`${servicesAndUpgradesUrl}/lifecycle-services`}
                                    render={() => (
                                      <Page
                                        data={this.state.data.pageLifecycle}
                                        layout={this.state.data.layout}
                                      />
                                    )}
                                  />
                                  <Route
                                    path={`${servicesAndUpgradesUrl}/training`}
                                    render={() => (
                                      <Page
                                        data={
                                          this.state.data.pageLifecycleTraining
                                        }
                                        layout={this.state.data.layout}
                                      />
                                    )}
                                  />
                                  <Route
                                    path={`${servicesAndUpgradesUrl}/upgrades-and-retrofit-solutions`}
                                    render={({
                                      match: { url: upgradesUrl },
                                    }) => (
                                      <Switch>
                                        <Route
                                          exact
                                          path={`${upgradesUrl}`}
                                          render={() => (
                                            <Page
                                              data={
                                                this.state.data
                                                  .pageLifecycleUpgrades
                                              }
                                              layout={this.state.data.layout}
                                            />
                                          )}
                                        />
                                        <Route
                                          path={`${upgradesUrl}/c300`}
                                          render={() => (
                                            <Page
                                              data={
                                                this.state.data
                                                  .pageLifecycleUpgradesC300
                                              }
                                              layout={this.state.data.layout}
                                            />
                                          )}
                                        />
                                      </Switch>
                                    )}
                                  />
                                </Switch>
                              )}
                            />
                            <Route
                              path={`${url}insights`}
                              render={({ match: { url: insightsUrl } }) => (
                                <Switch>
                                  <Route
                                    exact
                                    path={`${insightsUrl}`}
                                    render={() => (
                                      <Page
                                        data={this.state.data.pageInsights}
                                        layout={this.state.data.layout}
                                      />
                                    )}
                                  />
                                  <Route
                                    path={`${insightsUrl}/case-studies`}
                                    render={({
                                      match: { url: caseStudiesUrl },
                                    }) => (
                                      <Switch>
                                        <Route
                                          exact
                                          path={`${caseStudiesUrl}`}
                                          render={() => (
                                            <CaseStudies
                                              data={
                                                this.state.data.pageCaseStudies
                                              }
                                              layout={this.state.data.layout}
                                            />
                                          )}
                                        />
                                        <Route
                                          path={`${caseStudiesUrl}/audio-system`}
                                          render={() => (
                                            <Page
                                              data={
                                                this.state.data
                                                  .pageCaseStudyAudio
                                              }
                                              layout={this.state.data.layout}
                                            />
                                          )}
                                        />
                                        <Route
                                          path={`${caseStudiesUrl}/dial`}
                                          render={() => (
                                            <Page
                                              data={
                                                this.state.data
                                                  .pageCaseStudyDial
                                              }
                                              layout={this.state.data.layout}
                                            />
                                          )}
                                        />
                                      </Switch>
                                    )}
                                  />
                                  <Route
                                    path={`${insightsUrl}/future-face-of-nice`}
                                    render={() => (
                                      <Page
                                        data={
                                          this.state.data.pageFutureFaceOfNice
                                        }
                                        layout={this.state.data.layout}
                                      />
                                    )}
                                  />
                                </Switch>
                              )}
                            />
                            {/* <Route
                              path={`${url}news`}
                              render={({ match: { url: newsUrl } }) => (
                                <Switch>
                                  <Route
                                    exact
                                    path={`${newsUrl}`}
                                    render={() => (
                                      <News
                                        scheme={this.state.data.newsScheme}
                                        layout={this.state.data.layout}
                                      />
                                    )}
                                  />
                                  <Route
                                    path={`${newsUrl}/:slug`}
                                    render={() => (
                                      <NewsArticle
                                        layout={this.state.data.layout}
                                      />
                                    )}
                                  />
                                </Switch>
                              )}
                            /> */}
                            <Route
                              path={`${url}philosophy`}
                              render={() => (
                                <Page
                                  data={this.state.data.pagePhilosophy}
                                  layout={this.state.data.layout}
                                />
                              )}
                            />
                            <Route
                              path={`${url}privacy-policy`}
                              render={() => (
                                <Page
                                  data={this.state.data.pagePrivacyPolicy}
                                  layout={this.state.data.layout}
                                />
                              )}
                            />
                            <Route
                              path={`${url}system`}
                              render={({ match: { url: systemUrl } }) => (
                                <Switch>
                                  <Route
                                    exact
                                    path={`${systemUrl}`}
                                    render={() => (
                                      <Page
                                        data={this.state.data.pageSystem}
                                        layout={this.state.data.layout}
                                      />
                                    )}
                                  />
                                  <Route
                                    path={`${systemUrl}/audio`}
                                    render={({ match: { url: audioUrl } }) => (
                                      <Switch>
                                        <Route
                                          exact
                                          path={`${audioUrl}`}
                                          render={() => (
                                            <Page
                                              data={
                                                this.state.data.pageSystemAudio
                                              }
                                              layout={this.state.data.layout}
                                            />
                                          )}
                                        />
                                        <Route
                                          exact
                                          path={`${audioUrl}/omni-fi`}
                                          render={() => (
                                            <Page
                                              data={
                                                this.state.data
                                                  .pageSystemAudioOmniFi
                                              }
                                              layout={this.state.data.layout}
                                            />
                                          )}
                                        />
                                      </Switch>
                                    )}
                                  />
                                  <Route
                                    path={`${systemUrl}/connectivity`}
                                    render={() => (
                                      <Page
                                        data={
                                          this.state.data.pageSystemConnectivity
                                        }
                                        layout={this.state.data.layout}
                                      />
                                    )}
                                  />
                                  <Route
                                    path={`${systemUrl}/media`}
                                    render={() => (
                                      <Page
                                        data={this.state.data.pageSystemMedia}
                                        layout={this.state.data.layout}
                                      />
                                    )}
                                  />
                                  <Route
                                    path={`${systemUrl}/peds`}
                                    render={() => (
                                      <Page
                                        data={this.state.data.pageSystemPEDs}
                                        layout={this.state.data.layout}
                                      />
                                    )}
                                  />
                                  <Route
                                    path={`${systemUrl}/user-interface`}
                                    render={() => (
                                      <Page
                                        data={
                                          this.state.data
                                            .pageSystemUserInterface
                                        }
                                        layout={this.state.data.layout}
                                      />
                                    )}
                                  />
                                  <Route
                                    path={`${systemUrl}/video`}
                                    render={() => (
                                      <Page
                                        data={this.state.data.pageSystemVideo}
                                        layout={this.state.data.layout}
                                      />
                                    )}
                                  />
                                </Switch>
                              )}
                            />
                            <Route
                              render={() => (
                                <Page
                                  data={this.state.data.pageHome}
                                  layout={this.state.data.layout}
                                />
                              )}
                            />
                          </Switch>
                        )}
                      />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              )}
            />
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
