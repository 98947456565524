import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Used for bullet points with check mark throughout website
const PageTextBulletChallenge = (props) => (
  <div className={classNames(props.parentClass, 'bullet', props.class)}>
    <span className="bullet-icon challenge">
      <svg
        fill="none"
        height="48"
        viewBox="0 0 48 48"
        width="48"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="23.5" stroke="white" />
        <path d="M20 36L23 27H14L28 12L25 21H34L20 36Z" fill="white" />
      </svg>
    </span>
    <span className="bullet-text">{props.text}</span>
  </div>
);

PageTextBulletChallenge.propTypes = {
  class: PropTypes.string,
  parentClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

PageTextBulletChallenge.defaultProps = {
  class: '',
};

export default PageTextBulletChallenge;
