import React from 'react';
import PropTypes from 'prop-types';

import PageColumns from './PageColumns';
import PageDivider from './PageDivider';
import PageImage from './PageImage';
import PageImageLayered from './PageImageLayered';
import PageImageSlider from './PageImageSlider';
import PageLearnMore from './PageLearnMore';
import PageLink from './PageLink';
import PageQuickLook from './PageQuickLook';
import PageTeaser from './PageTeaser';
import PageTextBubble from './PageTextBubble';
import PageTextBulletChallenge from './PageTextBulletChallenge';
import PageTextBulletFact from './PageTextBulletFact';
import PageTextBulletNumber from './PageTextBulletNumber';
import PageTextContact from './PageTextContact';
import PageTextCopy from './PageTextCopy';
import PageTextGaOptout from './PageTextGaOptout';
import PageTextInfo from './PageTextInfo';
import PageTextList from './PageTextList';
import PageTextMainTitle from './PageTextMainTitle';
import PageTextMap from './PageTextMap';
import PageTextSectionTitle from './PageTextSectionTitle';
import PageTextSubTitle from './PageTextSubTitle';
import PageTextTitle from './PageTextTitle';
import PageTextTradeShowTeaser from './PageTextTradeShowTeaser';
import PageVideo from './PageVideo';
import PageVideoFramed from './PageVideoFramed';
import PageVideoTeaser from './PageVideoTeaser';

// Used to translate settings from page data into column layout
const PageColumnsContent = (props) => (
  <div className={props.parentClass}>
    {props.content.map((element, index) => {
      const elementProps = {
        key: index,
        parentClass: props.parentClass,
        ...element,
      };

      // Get component based on element type; check components for further information
      switch (element.type) {
        case 'bubble':
          return <PageTextBubble {...elementProps} />;

        case 'bullet':
          if (element.icon === 'challenge') {
            return <PageTextBulletChallenge {...elementProps} />;
          }
          if (element.icon === 'fact') {
            return <PageTextBulletFact {...elementProps} />;
          }
          if (element.icon === 'number') {
            return <PageTextBulletNumber {...elementProps} />;
          }
          break;

        case 'columns':
          return <PageColumns {...elementProps} />;

        case 'contact':
          return <PageTextContact {...elementProps} />;

        case 'copy':
          return <PageTextCopy {...elementProps} />;

        case 'divider':
          return <PageDivider {...elementProps} />;

        case 'image':
          return <PageImage {...elementProps} />;

        case 'imageLayered':
          return <PageImageLayered {...elementProps} />;

        case 'imageSlider':
          return <PageImageSlider {...elementProps} />;

        case 'info':
          return <PageTextInfo {...elementProps} />;

        case 'learnMore':
          return (
            <PageLearnMore
              {...elementProps}
              handleScrollTo={props.handleScrollTo}
            />
          );

        case 'link':
          return <PageLink {...elementProps} {...elementProps.link} />;

        case 'list':
          return <PageTextList {...elementProps} />;

        case 'mainTitle':
          return <PageTextMainTitle {...elementProps} />;

        case 'map':
          return <PageTextMap {...elementProps} />;

        case 'quickLook':
          return <PageQuickLook {...elementProps} />;

        case 'sectionTitle':
          return <PageTextSectionTitle {...elementProps} />;

        case 'subTitle':
          return <PageTextSubTitle {...elementProps} />;

        case 'teaser':
          return <PageTeaser {...elementProps} />;

        case 'title':
          return <PageTextTitle {...elementProps} />;

        case 'tradeShowTeaser':
          return <PageTextTradeShowTeaser {...elementProps} />;

        case 'video':
          return <PageVideo {...elementProps} />;

        case 'videoFramed':
          return <PageVideoFramed {...elementProps} />;

        case 'videoTeaser':
          return <PageVideoTeaser {...elementProps} />;

        case 'hyperlink':
          return <PageTextGaOptout {...elementProps} />;

        default:
          return <div key={index} />;
      }
    })}
  </div>
);

PageColumnsContent.propTypes = {
  content: PropTypes.array.isRequired,
  parentClass: PropTypes.string.isRequired,
};

export default PageColumnsContent;
